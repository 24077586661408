<template>
  <div>
    <div class="row content-header">
      <div class="content-header-left mb-2 col-md-9 col-12">
        <div class="row breadcrumbs-top">
          <div class="col-12">
            <h2 class="content-header-title float-left pr-1 mb-0">
              <feather-icon icon="ArrowLeftIcon" size="24" class="mr-2" @click="goBack" />Others
            </h2>
            <div class="breadcrumb-wrapper">
              <ol class="breadcrumb">
                <li class="breadcrumb-item active">
                  <span aria-current="location"> Add </span>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
    <validation-observer ref="simpleRules">
      <b-form action="apps-purchase" method="post" @submit="addMedicine">
        <b-card>
          <b-row>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  Purchase Date<span class="text-danger">*</span>
                </template>
                <validation-provider #default="{ errors }" name="purchase_date" rules="required">
                  <b-form-datepicker name="purchase_date" v-model="FormData.purchase_date" menu-class="w-100"
                    calendar-width="100%" class="mb-1" :max="maxDate" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  Production Type<span class="text-danger">*</span>
                </template>
                <div class="form-label-group">
                  <validation-provider #default="{ errors }" name=" Production Type" rules="required">
                    <v-select v-model="FormData.production_type_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="productionTypes" label="name" clearable:false :reduce="(e) => e.id" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  Source<span class="text-danger">*</span>
                </template>
                <div class="form-label-group">
                  <validation-provider #default="{ errors }" name=" Source" rules="required">
                    <v-select v-model="FormData.source_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="sourceList" label="name" clearable:false :reduce="(e) => e.id" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  Type<span class="text-danger">*</span>
                </template>
                <div class="form-label-group">
                  <validation-provider #default="{ errors }" name="Type" rules="required">
                    <v-select v-model="FormData.other_type" :options="typeList" label="label" clearable:false
                      :reduce="(e) => e.id" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </b-form-group>
            </b-col>

            <!--    -->
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  Pack Size<span class="text-danger">*</span>
                </template>
                <div class="form-label-group">
                  <validation-provider #default="{ errors }" name="Pack Size" rules="required">
                    <b-form-input v-model="FormData.pack_size" placeholder="Pack Size" type="text" name="pack_size" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  Quantity<span class="text-danger">*</span>
                </template>
                <div class="form-label-group">
                  <validation-provider #default="{ errors }" name="Quantity" rules="required">
                    <b-form-input v-model="FormData.quantity" placeholder="Quantity" type="text" name="quantity" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  Rate Per Pack (Rs.)<span class="text-danger">*</span>
                </template>
                <div class="form-label-group">
                  <validation-provider #default="{ errors }" name=" Rate Per Pack (Rs.)"
                    :rules="{ regex: RegExp(/^\d+(\.\d{0,2})?$/) }">
                    <b-form-input v-model="FormData.rate_per_pack" placeholder="Rate Per Pack (Rs.)" type="text"
                      name="rate_per_pack" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  Batch No. <span class="text-danger">*</span>
                </template>
                <div class="form-label-group">
                  <validation-provider #default="{ errors }" name=" Batch No. " rules="required">
                    <b-form-input v-model="FormData.batch_no" placeholder="Batch No. " type="text" name="batch_no" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </b-form-group>
            </b-col>
            <!-- submit and reset -->
            <b-col md="12">
              <b-button type="submit" variant="primary" value="Submit" class="mr-1 mb-3">
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import Logo from "@core/layouts/components/Logo.vue";

import * as Vue from "vue";
import axios from "axios";

import { BASE_URL } from "@core/common/constants";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import axiosIns from "@/libs/axios";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";

export default {
  components: {
    flatPickr,
    vSelect,
    Logo,
  },

  data() {
    return {
      FormData: {
        purchase_date: null,
        production_type_id: null,
        source_id: null,
        other_type: null,
        pack_size: null,
        quantity: null,
        rate_per_pack: null,
        batch_no: null,
      },
      typeList: [
        { label: "One", id: 1 },
        { label: "Two", id: 2 },
      ],
      productionTypes: [],
      sourceList: [],
      maxDate: new Date().toISOString().split("T")[0],
    };
  },
  created: function () {
    this.getSource();
    this.getProductionTypes();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    getProductionTypes() {
      axiosIns
        .get(`web/production-types`)
        .then((response) => {
          this.productionTypes = response.data.production_type.data;
        })
        .catch((error) => {
          this.$refs.setErrors(error.response.data.error);
        });
    },
    getSource() {
      axiosIns
        .get(`web/get-all-sources`)
        .then((response) => {
          this.sourceList = response.data.source_list;
        })
        .catch((error) => {
          this.$refs.setErrors(error.response.data.error);
        });
    },
    // getRoute() {
    //   axiosIns
    //     .get(`web/routeType/medicine`)
    //     .then((response) => {
    //       this.routeList = response.data.routeList;
    //     })
    //     .catch((error) => {
    //       this.$refs.setErrors(error.response.data.error);
    //     });
    // },
    // getUnit() {
    //   axiosIns
    //     .get(`web/units/medicine`)
    //     .then((response) => {
    //       this.unitList = response.data.unitList;
    //     })
    //     .catch((error) => {
    //       this.$refs.setErrors(error.response.data.error);
    //     });
    // },
    // getSelectedUnit(id) {
    //   this.selectedUnit = id;
    // },
    // getSelectedRoute(id) {
    //   this.selectedRoute = id;
    // },
    addMedicine(e) {
      e.preventDefault();
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          var data = new FormData();
          data.append("purchase_date", this.FormData.purchase_date);
          data.append("production_type_id", this.FormData.production_type_id);
          data.append("source_id", this.FormData.source_id);
          data.append("other_type", this.FormData.other_type);
          data.append("pack_size", this.FormData.pack_size);
          data.append("quantity", this.FormData.quantity);
          data.append("rate_per_pack", this.FormData.rate_per_pack);
          data.append("batch_no", this.FormData.batch_no);
          axiosIns
            .post(`web/purchase-other`, data)
            .then((res) => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  icon: "CoffeeIcon",
                  variant: "success",
                  text: `Data Successfully Added `,
                },
              });
              this.$router.push({
                name: `apps-company-purchase`,
              });
            })
            .catch((error) => {
              const data = error.response.data.errors;
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  icon: "CoffeeIcon",
                  variant: "danger",
                  text: data,
                },
              });
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {

  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
